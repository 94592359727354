var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"whiteBg"},[_c('div',{staticClass:"text"},[_vm._m(0),_c('slick',{ref:"slick",attrs:{"options":_vm.slickOptions}},_vm._l((_vm.items),function(item){return _c('a',{key:item.img,attrs:{"target":"_blank","href":item.link}},[_c('div',{staticClass:"item",style:({
            background:
              'linear-gradient(180deg, rgba(94, 94, 94, 0) 0%, rgba(94, 94, 94, 0.05) 54.69%, rgba(0, 0, 0, 0.5) 100%), url(' +
              require(`@/assets/img/landing/interview/${item.img}.png`) +
              ')',
            backgroundSize: 'cover',
            position: 'relative',
          })},[_c('div',{staticClass:"interview"},[_c('p',{staticClass:"title"},[[_vm._v(" "+_vm._s(item.title)+" ")]],2),_c('p',{staticClass:"sub-title",domProps:{"innerHTML":_vm._s(item.sub)}})])])])}),0)],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('h2',{staticClass:"font-weight-bold sub"},[_vm._v(" 슈퍼멤버스와 함께하는 "),_c('span',{staticClass:"mobile"}),_vm._v("사장님들의"),_c('span',{staticClass:"desktop"}),_vm._v(" 솔직한"),_c('span',{staticClass:"mobile"}),_vm._v(" 이용후기를 들어보세요. ")])
}]

export { render, staticRenderFns }