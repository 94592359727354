<template>
  <div class="whiteBg">
    <div class="container row2">
      <div class="text">
        <h2 class="font-weight-bold">
          이용하는 모든 블로거에게<span class="mobile" /> 혜택을<span
            class="desktop"
          />
          무료로 제공할<span class="mobile" /> 필요가 없습니다.
        </h2>
        <p class="font-weight-light">
          회원의 등급에 따라 할인율을 적용하고<br />
          나머지 금액은 회원이 결제합니다.
        </p>
      </div>
      <img :src="image" class="row-image mx-2" />
    </div>
  </div>
</template>

<script>
import userMixin from '@/components/shared/userMixin';

export default {
  name: 'Row2',
  mixins: [userMixin],
  data() {
    return {
      image:
        window.innerWidth > 960
          ? require('@/assets/img/landing/ceo-landing/reward.png')
          : require('@/assets/img/landing/ceo-landing/reward-mobile.png'),
    };
  },
  mounted() {
    window.addEventListener('resize', () => {
      this.image =
        window.innerWidth > 960
          ? require('@/assets/img/landing/ceo-landing/reward.png')
          : require('@/assets/img/landing/ceo-landing/reward-mobile.png');
    });
  },
};
</script>

<style scoped lang="scss">
.row2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 72px 0;
  @media (max-width: 960px) {
    padding: 80px 0px 56px;
    overflow: hidden;
  }
}
.row2 .row-image {
  width: 707px;
  margin: 12px 0 0 0;
  @media (max-width: 960px) {
    max-width: 400px;
    margin: 16px auto;
    width: 100%;
    text-align: center;
  }
}

.text {
  .eyebrow {
    color: #757575;
    margin-bottom: 4px;
    margin-left: 1px;
    font-size: 14px;
  }
  h2 {
    margin-bottom: 12px;
    color: #212121;
  }
  p {
    color: #484848;
    line-height: 1.8;
  }
  @media (max-width: 960px) {
    // padding-top: 0;
    // margin-bottom: auto;
    .eyebrow {
      margin-bottom: 6px;
      font-size: 13px;
    }
    h2 {
      margin-bottom: 12px;
    }
    p {
      color: #484848;
      line-height: 1.94;
    }
  }
}

.rank-button {
  background: #ffffff;
  border: 2px solid #bd1328;
  border-radius: 16px;
  color: #bd1328;
  font-size: 14px;
}

.whiteBg {
  background-color: #fff;
}
</style>
