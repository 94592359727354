<template>
  <div class="references">
    <div class="text">
      <!-- <h5 class="eyebrow">
          많은 업체에서 이미 경험하셨습니다.
      </h5> -->
      <h2 class="font-weight-bold">슈퍼멤버스와 함께한 회원사</h2>
      <p class="font-weight-light mb-10">
        약 2000개 이상의 업체와 프랜차이즈 본사에서<span class="mobile" />
        슈퍼멤버스를 이용하고 있습니다.
      </p>
    </div>
    <div v-if="$vuetify.breakpoint.mdAndUp" class="logos-desktop">
      <div class="marquee-desktop-1" />
      <div class="marquee-desktop-2 mt-6" />
    </div>
    <div v-if="$vuetify.breakpoint.smAndDown" class="logos-mobile">
      <div class="marquee-mobile-1" />
      <div class="marquee-mobile-2 mt-4" />
      <div class="marquee-mobile-3 mt-4" />
    </div>
  </div>
</template>

<script>
export default {
  name: "References",
};
</script>

<style scoped lang="scss">
.references {
  display: flex;
  flex-direction: column;
  align-content: center;
  padding: 72px 0;
  text-align: center;
  .text {
    .eyebrow {
      color: #757575;
      margin-bottom: 4px;
      margin-left: 1px;
    }

    h2 {
      margin-bottom: 8px;
      color: #212121;
    }
    p {
      color: #484848;
      line-height: 1.8;
    }
  }
  .row-image {
    width: 100%;
    // height: 380px;
    padding: 0 auto;
    img {
      width: 100%;
      padding: auto 24px;
      // height: 374px;
    }
  }
}

@media (max-width: 960px) {
  .references {
    justify-content: flex-start;
    align-content: center;
    padding: 56px 0 72px;
    overflow: hidden;
    .text {
      .eyebrow {
        margin-bottom: 6px;
      }
      p {
        color: #484848;
        line-height: 1.94;
      }
      padding: 0;
      text-align: center;
    }
  }
}

@media (max-width: 724px) {
  .row-image {
    margin: 0 0 56px;
    // width: 100%;
    // height: 380px;
    padding: 0 24px !important;
    img {
      // margin: 0 calc(50vw - 122px);
      max-width: 327px;
    }
  }
}

.logos-desktop div {
  height: 56px;
}
.marquee-desktop-1 {
  background: url("https://firebasestorage.googleapis.com/v0/b/phoz-7f0de/o/landing%2Freferences%2Fmarquee-desktop-1.png?alt=media&token=a266dafb-5ff6-45ef-8459-f3c55fb2ba8a")
    0 center / 1964px repeat-x;
  animation: movebg-desktop 29s linear infinite;
}
.marquee-desktop-2 {
  background: url("https://firebasestorage.googleapis.com/v0/b/phoz-7f0de/o/landing%2Freferences%2Fmarquee-desktop-2.png?alt=media&token=a35b346b-a1af-4273-8386-f41389ced514")
    0 center / 1964px repeat-x;
  animation: movebg-desktop 37s linear infinite;
}

.logos-mobile div {
  height: 48px;
}
.marquee-mobile-1 {
  background: url("https://firebasestorage.googleapis.com/v0/b/phoz-7f0de/o/landing%2Freferences%2Fmarquee-mobile-1.png?alt=media&token=a266dafb-5ff6-45ef-8459-f3c55fb2ba8a")
    0 center / 1121px repeat-x;
  animation: movebg-mobile 21s linear infinite;
}
.marquee-mobile-2 {
  background: url("https://firebasestorage.googleapis.com/v0/b/phoz-7f0de/o/landing%2Freferences%2Fmarquee-mobile-2.png?alt=media&token=a35b346b-a1af-4273-8386-f41389ced514")
    0 center / 1121px repeat-x;
  animation: movebg-mobile 26s linear infinite;
}
.marquee-mobile-3 {
  background: url("https://firebasestorage.googleapis.com/v0/b/phoz-7f0de/o/landing%2Freferences%2Fmarquee-mobile-3.png?alt=media&token=35721e7a-86a5-438e-95c5-ee5db081d32d")
    0 center / 1121px repeat-x;
  animation: movebg-mobile 23s linear infinite;
}

@keyframes movebg-desktop {
  0% {
    background-position: 0 center;
  }
  100% {
    background-position: -1964px center;
  }
}

@keyframes movebg-mobile {
  0% {
    background-position: 0 center;
  }
  100% {
    background-position: -1121px center;
  }
}

.whiteBg {
  background-color: #fff;
}
</style>
