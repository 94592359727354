<template>
  <div class="whiteBg">
    <div class="text">
      <h2 class="font-weight-bold sub">
        슈퍼멤버스와 함께하는 <span class="mobile" />사장님들의<span
          class="desktop"
        />
        솔직한<span class="mobile" />
        이용후기를 들어보세요.
      </h2>
      <slick ref="slick" :options="slickOptions">
        <a
          v-for="item in items"
          :key="item.img"
          target="_blank"
          :href="item.link"
        >
          <div
            class="item"
            :style="{
              background:
                'linear-gradient(180deg, rgba(94, 94, 94, 0) 0%, rgba(94, 94, 94, 0.05) 54.69%, rgba(0, 0, 0, 0.5) 100%), url(' +
                require(`@/assets/img/landing/interview/${item.img}.png`) +
                ')',
              backgroundSize: 'cover',
              position: 'relative',
            }"
          >
            <div class="interview">
              <p class="title">
                <template>
                  {{ item.title }}
                </template>
              </p>
              <p class="sub-title" v-html="item.sub"></p>
            </div>
          </div>
        </a>
      </slick>
    </div>
  </div>
</template>

<script>
import Slick from 'vue-slick';

export default {
  name: 'InterviewReview',
  components: {
    Slick,
  },
  data() {
    return {
      slickOptions: {
        arrows: false,
        autoplay: true,
        autoplaySpeed: 6000,
        variableWidth: true,
        centerMode: true,
        centerPadding: '10px',
        slidesToShow: 1,
        // Any other options that can be got from plugin documentation
      },
      items: [
        {
          img: '1',
          link: 'https://blog.naver.com/supermembers/223086779673',
          title: '시민식당 본점 사장님',
          sub: '"마케팅의 핵심, 구글 리뷰 기능을 <span class="mobile"></span> 무료로 제공해줘요"',
        },

        {
          img: '2',
          link: 'https://blog.naver.com/supermembers/223091140330',
          title: '쟁반집 신촌점 사장님',
          sub: '"타업체들과는 차원이 다른 신뢰 때문입니다"',
        },
        {
          img: '3',
          link: 'https://blog.naver.com/supermembers/223090783575',
          title: '홍홍 용산점 사장님',
          sub: '"15만 원이라는 뛰어난 가성비 때문이죠"',
        },
      ],
    };
  },
  methods: {
    next() {
      this.$refs.slick.next();
    },

    prev() {
      this.$refs.slick.prev();
    },
  },
};
</script>

<style scoped lang="scss">
.item {
  width: 680px !important;
  height: 408px !important;
  border-radius: 16px;

  .interview {
    position: absolute;
    text-align: left;
    left: 32px;
    bottom: 36px;
    .title {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      color: #ffffff;
      margin-bottom: 8px;
    }

    .sub-title {
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 30px;
      /* identical to box height */

      color: #ffffff;
    }
  }
}

@media (max-width: 960px) {
  .item {
    width: 345px !important;
    height: 208px !important;
  }

  .interview {
    left: 24px !important;
    bottom: 24px !important;

    .title {
      font-size: 12px !important;
      line-height: 15px !important;
    }

    .sub-title {
      font-size: 14px !important;
      line-height: 18px !important;
    }
  }

  .eyebrow {
    font-size: 13px;
    line-height: 16px;
  }

  .sub {
    font-size: 24px;
    line-height: 36px;
  }
}

.text {
  text-align: center;
}

.eyebrow {
  font-style: normal;
  font-size: 14px;
  text-align: center;
  color: #757575;
}

.sub {
  font-weight: 700;
  font-size: 32px;
  line-height: 48px;
  color: #212121;
  margin-bottom: 60px;
}

.whiteBg {
  background-color: #fff;
  padding-top: 72px;
  padding-bottom: 104px;
}

// .review {
//   width: 100vw;
//   padding: 112px 0;
//   background-color: #fbfbfb;
//   // background-color: var(--light-pink);

//   .title {
//     text-align: center;
//     color: #212121;
//     margin-bottom: 24px;
//     line-height: 48px;
//     font-weight: bold;
//   }

//   ::v-deep .slick-slide {
//     padding: 32px 16px 48px;
//   }
//   ::v-deep .slick-arrow {
//     color: transparent;
//     background-color: transparent;
//     position: absolute;
//     width: 20%;
//     height: 390px;
//     top: 20px;
//     z-index: 2;
//   }
//   ::v-deep .slick-prev {
//     left: 0;
//   }
//   ::v-deep .slick-next {
//     right: 0;
//   }

//   .item {
//     width: 720px !important;
//     height: 390px;
//     border-radius: 16px;
//     box-shadow: 0px 16px 32px rgba(0, 0, 0, 0.1);
//     background: #ffffff;
//     background: var(--white-two-color);
//     .content {
//       height: 100%;
//       display: flex;
//       flex-direction: column;
//       justify-content: space-between;
//       align-items: space-between;
//       padding: 60px 124px 64px;
//       .text {
//         width: 100%;
//         line-height: 1.8;
//         text-align: left;
//         margin-top: 0;
//         margin-bottom: auto;
//         color: #757575;
//         font-weight: 300;
//         font-size: 15px;
//         strong {
//           color: #484848;
//           font-weight: 500;
//         }
//         i {
//           color: #dbdbdb;
//           display: block;
//         }
//       }
//       .profile {
//         display: flex;
//         align-items: center;
//         img {
//           width: 80px;
//           height: 80px;
//           border-radius: 50%;
//           margin-right: 24px;
//         }
//         h5 {
//           line-height: 1.8;
//         }
//       }
//     }
//   }
// }

// @media (max-width: 960px) {
//   .review {
//     padding: 80px 0 0;
//     .title {
//       line-height: 36px;
//     }
//     .slick-arrow {
//       width: 40px;
//       top: 5px;
//     }
//     .item {
//       width: 296px !important;
//       height: 428px;
//       margin-bottom: 120px;
//       .content {
//         padding: 40px 32px;
//         .text {
//           font-size: 14px;
//         }
//         .profile {
//           img {
//             width: 64px;
//             height: 64px;
//             margin-right: 20px;
//           }
//         }
//       }
//     }
//   }
// }
</style>
