<template>
  <div class="whiteBg">
    <div class="container row1">
      <div class="text">
        <h2 class="font-weight-bold">
          슈퍼멤버스가 인증하는<span class="mobile" /> 블로거를<span
            class="desktop"
          />
          만나보세요.
        </h2>
        <p class="font-weight-light">
          슈퍼멤버스는 국내 모든 블로그의<span class="mobile" /> 영향력을
          평가하고 있습니다.<br />
          슈퍼멤버스 최상위 등급인 블랙등급 회원의 경우,<br />
          전체 블로그의 상위 5% 블로거입니다.
        </p>
      </div>
      <img :src="image" class="row-image mx-2" />
      <div class="text" style="display: none">
        <p class="font-weight-light">
          슈퍼멤버스가 어떻게 블로그를<span class="mobile" /> 평가하는지
          궁금하신가요?<br />
          슈퍼멤버스 랭킹 서비스를 확인해보세요.
          <a
            href="https://www.supermembers.co.kr/rank"
            target="_blank"
            style="display: block; margin-top: 16px"
          >
            <button id="storePage1" class="rank-button">
              슈퍼멤버스 랭킹 둘러보기
            </button>
          </a>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import userMixin from '@/components/shared/userMixin';

export default {
  name: 'Row1',
  mixins: [userMixin],
  data() {
    return {
      image:
        window.innerWidth > 960
          ? require('@/assets/img/landing/ceo-landing/level.png')
          : require('@/assets/img/landing/ceo-landing/level-mobile.png'),
    };
  },
  mounted() {
    window.addEventListener('resize', () => {
      this.image =
        window.innerWidth > 960
          ? require('@/assets/img/landing/ceo-landing/level.png')
          : require('@/assets/img/landing/ceo-landing/level-mobile.png');
    });
  },
};
</script>

<style scoped lang="scss">
.row1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 72px 0;
  @media (max-width: 960px) {
    padding: 80px 0px 56px;
    overflow: hidden;
  }
}
.row1 .row-image {
  width: 596px;
  margin: 12px 0 0 0;
  @media (max-width: 960px) {
    max-width: 425px;
    margin: 16px auto;
    width: 100%;
    text-align: center;
  }
}

.text {
  .eyebrow {
    color: #757575;
    margin-bottom: 4px;
    margin-left: 1px;
    font-size: 14px;
  }
  h2 {
    margin-bottom: 12px;
    color: #212121;
  }
  p {
    color: #484848;
    line-height: 1.8;
  }
  @media (max-width: 960px) {
    // padding-top: 0;
    // margin-bottom: auto;
    .eyebrow {
      margin-bottom: 6px;
      font-size: 13px;
    }
    h2 {
      margin-bottom: 12px;
    }
    p {
      color: #484848;
      line-height: 1.94;
    }
  }
}

.rank-button {
  background: #ffffff;
  border: 2px solid #ea2a3a;
  border-radius: 16px;
  color: #ea2a3a;
  font-size: 14px;
}

.whiteBg {
  background-color: #fbfbfb;
}
</style>
