<template>
  <div class="whiteBg">
    <div class="tab-page container downloads">
      <div class="text">
        <!-- <h5 class="eyebrow">
            슈퍼멤버스에 대해 더 궁금한 점이 있으신가요?
        </h5>   -->
        <!-- <h2 class="font-weight-bold">
          슈퍼멤버스의 자신감, <br />
          지금 확인해보세요.
        </h2> -->
        <!-- <router-link v-if="userIsAuthenticated" to="/company/modads">
          <button id="signupPage11" class="signup blackBg">가맹점 가입하기<span style="float: right; margin-right: 20px"> > </span></button>
        </router-link>
        <router-link v-else to="/signup/">
          <button id="signupPage12" class="signup blackBg">가맹점 가입하기<span style="float: right; margin-right: 20px"> > </span></button>
        </router-link> -->
        <div class="mt-8">
          <!-- <a target="_blank" href="https://firebasestorage.googleapis.com/v0/b/phoz-7f0de/o/landing%2F%5B%E1%84%86%E1%85%A2%E1%84%8C%E1%85%A1%E1%86%BC%5D%E1%84%89%E1%85%B2%E1%84%91%E1%85%A5%E1%84%86%E1%85%A6%E1%86%B7%E1%84%87%E1%85%A5%E1%84%89%E1%85%B3%20%E1%84%89%E1%85%A9%E1%84%80%E1%85%A2%E1%84%89%E1%85%A5.pdf?alt=media&token=39174cf1-a8c6-485a-99ba-4787fa29dc9d">
            <button id="storePage1" class="download">
              매장 서비스소개서<i class="material-icons" style="float: right; margin-right: 12px">get_app</i>
            </button>
          </a>
          <a target="_blank" href="https://firebasestorage.googleapis.com/v0/b/phoz-7f0de/o/landing%2F%5B%E1%84%8C%E1%85%A6%E1%84%91%E1%85%AE%E1%86%B7%5D%E1%84%89%E1%85%B2%E1%84%91%E1%85%A5%E1%84%86%E1%85%A6%E1%86%B7%E1%84%87%E1%85%A5%E1%84%89%E1%85%B3%20%E1%84%89%E1%85%A9%E1%84%80%E1%85%A2%E1%84%89%E1%85%A5.pdf?alt=media&token=e1bf29e8-e2a1-479f-b655-f90ab4aada90">
            <button id="productPage1" class="download">
              제품 서비스소개서<i class="material-icons" style="float: right; margin-right: 12px">get_app</i>
            </button>
          </a> -->
          <!-- <router-link to="/signup/"> -->
          <!-- <button
            id="storePage3"
            class="signup newPrimaryBg pl-4 py-0 mb-4"
            style="line-height: 26px"
            @click="showConsultDialog = true"
          >
            도입 문의하기
            <v-icon style="color: #fff; margin: 0 0 2px 4px"
              >chevron_right</v-icon
            >
          </button> -->

          <!-- </router-link> -->
          <!-- <h5 class="eyebrow">상위노출 효과를 직접 경험하세요.</h5> -->
        </div>

        <v-row justify="center" align="center">
          <v-col class="text-center pt-16">
            <v-row
              style="
                display: block;
                font-style: normal;
                font-weight: 700;
                font-size: 32px;
                line-height: 48px;
                text-align: center;
                color: #212121;
              "
            >
              슈퍼멤버스 도입 문의
            </v-row>
            <v-row
              class="body--matching mt-3"
              style="
                display: block;
                font-style: normal;
                font-weight: 700;
                font-size: 15px;
                line-height: 27px;
                /* identical to box height, or 180% */

                text-align: center;

                color: #484848;
              "
            >
              딱 맞는 블로그 마케팅을 제안받아 보세요.
            </v-row>
            <v-row class="mb-16 mt-6" style="display: block">
              <v-col justify="center" align="center">
                <div
                  class="pipedrive-request pa-8 ma-4"
                  :style="
                    $vuetify.breakpoint.mdAndUp
                      ? 'width: 460px'
                      : 'min-width: 320px'
                  "
                >
                  <div
                    class="pipedriveWebForms"
                    data-pd-webforms="https://webforms.pipedrive.com/f/6xKYJT5eVb7WHCo3GQ2t5NxOJ3n9HhZXn4g95q3aYuKXFE9K0Nfhrw68S6Tq1NmNa3"
                  ></div>
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Downloads",
  mounted() {
    let pipedriveScript = document.createElement("script");
    pipedriveScript.setAttribute(
      "src",
      "https://webforms.pipedrive.com/f/loader"
    );
    document.head.appendChild(pipedriveScript);
  },
  data() {
    return {
      showConsultDialog: false,
    };
  },
};
</script>

<style scoped lang="scss">
.downloads {
  display: flex;
  flex-direction: row;
  align-content: center;
  .text {
    text-align: center;
    margin: 120px auto;
    .eyebrow {
      color: #757575;
      margin-bottom: 4px;
      margin-left: 1px;
    }
    h2 {
      color: #212121;
    }
    p {
      color: #484848;
      line-height: 1.8;
    }
    button {
      background-color: #ea2a3a;
      height: 48px;
      border-radius: 24px;
      font-size: 14px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
    }
  }
}

@media (max-width: 960px) {
  .downloads {
    flex-direction: column-reverse;
    justify-content: flex-end;
    align-content: center;
    overflow: hidden;
    margin: 0 auto 0;
    .row-image {
      width: 375px;
      height: auto;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .text {
      margin: 112px auto;
      padding-top: 0;
      .eyebrow {
        margin-bottom: 6px;
      }
      h2 {
      }
      p {
        color: #484848;
        line-height: 1.94;
      }
    }
    button {
      background-color: #fff;
      height: 48px;
      border-radius: 24px;
      font-size: 14px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
    }
  }
}

.signup {
  width: 220px;
  height: 48px;
  color: #fff;
  border-radius: 24px;
  box-shadow: 0 12px 24px 0 rgba(75, 75, 75, 0.1);
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
}

.whiteBg {
  background-color: #fbfbfb;
}

.pipedrive-request {
  background-color: white;
  border-radius: 24px;
  width: 200px;
  filter: drop-shadow(0px 16px 32px rgba(0, 0, 0, 0.1));
}
</style>
